<template>
  <editor-content :editor="editor" class="tiptap" />
</template>

<script>
import { Editor } from "@tiptap/core";
import { EditorContent } from "@tiptap/vue-2";

import Document from "@tiptap/extension-document";
import Heading from "@tiptap/extension-heading";
import Text from "@tiptap/extension-text";
import Placeholder from "@tiptap/extension-placeholder";

import { DisableEnter } from "@/helpers/tiptap/DisableEnter";

export default {
  name: "TipTapDocument",
  components: { EditorContent },
  props: {
    id: String,
    placeholder: {
      type: String,
      default: "Tell your story...",
    },
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      editor: null,
    };
  },
  mounted() {
    this.initializeEditor();
  },
  beforeDestroy() {
    this.editor.off("update", this.handleUpdate);

    this.editor.destroy();
  },
  methods: {
    initializeEditor() {
      const { placeholder } = this;
      let { value } = this;
      if (!value) value = "<h5></h5>";

      this.editor = new Editor({
        extensions: [
          DisableEnter,
          Document,
          Text,
          Heading.configure({ levels: [5] }),
          Placeholder.configure({
            emptyEditorClass: "is-editor-empty",
            emptyNodeClass: "is-empty",
            placeholder,
          }),
        ],
        content: value,
      });

      this.editor.on("update", this.handleUpdate);
    },
    handleUpdate() {
      let content = this.editor.getHTML();
      console.log("content", content);

      if (content && !content.startsWith("<h5>") && content.trim() !== "") {
        this.editor.setContent(`<h5>${content}</h5>`, false);
        content = `<h5>${content}</h5>`;
      }

      const cleanContent = content.replace(/<\/?h5>/g, "");

      this.$emit("update:value", cleanContent);
    },
  },
};
</script>
