<template>
  <div class="h-100">
    <base-document :key="documentId" :project-id="projectId" :document-id="documentId" />
  </div>
</template>

<script>
import BaseDocument from "@/components/Base/Document";

export default {
  name: "Document",
  components: { BaseDocument },
  computed: {
    projectId() {
      return this.$route.params.projectId;
    },
    documentId() {
      return this.$route.params.documentId;
    },
  },
};
</script>
