<template>
  <div class="h-100">
    <div v-if="!isLoading">
      <div v-if="data?.isLocked">
        <em>Generating the new persona. Come back in a few minutes.</em>
      </div>

      <div v-else>
        <b-row no-gutters>
          <b-col>
            <b-button v-if="isShowingClose" @click="$emit('close')" variant="transparent" class="mt-2 p-0 float-left">
              <img src="@/assets/icons/close.svg" class="icon icon-sm" />
            </b-button>

            <base-dropdown
              class="float-right"
              @remove="triggerRemoveDocument"
              @undo="triggerUndoHistory"
              @redo="triggerRedoHistory"
              :canUndo="canUndo"
              :canRedo="canRedo"
            />
          </b-col>
        </b-row>

        <b-row no-gutters>
          <b-col>
            <tip-tap-headline v-if="!isMobile" :value.sync="newName" placeholder="No Name" class="mt-4" />
            <tip-tap-headline-small v-else :value.sync="newName" placeholder="No Name" class="d-inline-block mt-2 ml-1" />
          </b-col>
        </b-row>

        <b-row v-if="templateId" no-gutters>
          <b-col cols="2">
            <small>Template</small>
          </b-col>

          <b-col>
            <small class="text-capitalize">
              {{ templateId }}
            </small>
          </b-col>
        </b-row>

        <b-row v-if="templateId === TEMPLATES.BLOG_POST" no-gutters>
          <b-col cols="2">
            <small>Structure</small>
          </b-col>

          <b-col>
            <small class="text-capitalize">
              <base-select-native v-model="newStructureId" :options="STRUCTURES_OPTIONS[TEMPLATES.BLOG_POST]" />
            </small>
          </b-col>
        </b-row>

        <hr />

        <div v-if="type === DOCUMENTS_TYPES.FOLDER">
          <b-row v-if="children && children.length" no-gutters>
            <b-col v-for="child in children" :key="child.id" @click="goToItem(child)" cols="3" class="p-2">
              <div class="pt-2 pb-2 pl-4 pr-4 bg-black rounded w-100 cursor-pointer">
                <strong>{{ child.name }}</strong>
              </div>
            </b-col>
          </b-row>

          <p v-else><em>This folder is empty.</em></p>
        </div>

        <tip-tap-document
          v-else
          :key="keyTiptap"
          :project-id="projectId"
          :value.sync="newContent"
          placeholder="Start writing here..."
          class="mt-2"
          :style="styleDocument"
        />

        <layout-spacer />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { GET, CREATE, UPDATE, REMOVE, INIT_HISTORY, UNDO_HISTORY, REDO_HISTORY } from "@/store/actions";

import { BRow, BCol, BButton } from "bootstrap-vue";
import debounce from "lodash/debounce";

import { DOCUMENTS_TYPES, TEMPLATES, STRUCTURES_OPTIONS } from "@/constants/documents";

import { generateKey } from "@/helpers/utils";
// import { reconstructContentFromHistory } from "@/helpers/history";

import TipTapHeadline from "@/components/Forms/TipTap/Headline";
import TipTapHeadlineSmall from "@/components/Forms/TipTap/HeadlineSmall";
import TipTapDocument from "@/components/Forms/TipTap/Document/";

import BaseSelectNative from "@/components/Forms/Base/BaseSelect/Native";
import BaseDropdown from "@/components/Base/Dropdown";
import LayoutSpacer from "@/components/Layouts/Spacer";

export default {
  name: "TaskDocument",
  components: {
    BRow,
    BCol,
    BButton,
    BaseSelectNative,
    BaseDropdown,
    TipTapHeadline,
    TipTapHeadlineSmall,
    TipTapDocument,
    LayoutSpacer,
  },
  props: {
    projectId: {
      type: String,
      required: true,
    },
    documentId: {
      type: String,
      required: true,
    },

    isShowingClose: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({ documents: (state) => state.projectsDocuments.items }),
    ...mapGetters("projectsDocuments", ["documentsByParentId"]),
    ...mapGetters("projectsDocumentsHistory", ["canUndo", "canRedo"]),
    data() {
      const { documentId } = this;
      return this.documents[documentId];
    },
    children() {
      const { documentId } = this;
      return this.documentsByParentId({ parentId: documentId });
    },

    styleDocument() {
      const { isMobile } = this;

      if (!isMobile) return "margin-left: 35px";
      return "";
    },

    isMobile() {
      const { value } = this.$screen.alias;
      return value === "xs" || value === "sm" || value === "md";
    },
  },
  data: () => ({
    type: null,
    templateId: null,
    structureId: null,

    newName: null,
    newStructureId: null,
    newContent: null,

    keyTiptap: generateKey(),

    isLoading: true,
    isSending: false,

    DOCUMENTS_TYPES,
    TEMPLATES,
    STRUCTURES_OPTIONS,
  }),
  async mounted() {
    this.triggerGetDocument();
    // this.initHistory({ projectId: this.projectId, docId: this.documentId });
    // const historyEntries = await reconstructContentFromHistory(this.projectId, this.documentId);
    // console.log(historyEntries);
  },
  methods: {
    ...mapActions("projectsDocuments", { getDocument: GET, updateDocument: UPDATE, removeDocument: REMOVE }),
    ...mapActions("projectsDocumentsBlocks", { createDocumentBlock: CREATE }),
    ...mapActions("projectsDocumentsHistory", {
      initHistory: INIT_HISTORY,
      undoHistory: UNDO_HISTORY,
      redoHistory: REDO_HISTORY,
    }),
    async triggerGetDocument() {
      this.isLoading = true;

      const { projectId, documentId: id } = this;
      const { name, type, templateId, structureId = null, content } = await this.getDocument({ projectId, id });

      this.type = type;
      this.templateId = templateId;
      this.newStructureId = structureId;
      this.newName = name;
      this.newContent = content;

      this.$watch("newName", () => { this.updateName(); }); /* prettier-ignore */
      this.$watch("newStructureId", () => { this.updateStructureId(); }); /* prettier-ignore */
      this.$watch("newContent", () => { this.updateContent(); }); /* prettier-ignore */

      this.isLoading = false;
    },
    async triggerRemoveDocument() {
      this.isSending = true;

      const { projectId, documentId: id } = this;
      await this.removeDocument({ projectId, id });

      this.$router.push({ name: "Project" });

      this.isSending = false;
    },

    async triggerUpdateName() {
      this.isSending = true;

      const { projectId, documentId: id, newName: name } = this;
      await this.updateDocument({ projectId, id, name });

      this.isSending = false;
    },
    async updateStructureId() {
      this.isSending = true;

      const { projectId, documentId: id, newStructureId: structureId } = this;
      await this.updateDocument({ projectId, id, structureId });

      this.isSending = false;
    },
    async triggerUpdateContent() {
      this.isSending = true;

      const { projectId, documentId: id, newContent: content } = this;
      await this.updateDocument({ projectId, id, content });

      this.isSending = false;
    },

    triggerUndoHistory() {
      this.undoHistory({ projectId: this.projectId, documentId: this.documentId });
    },
    triggerRedoHistory() {
      this.redoHistory({ projectId: this.projectId, documentId: this.documentId });
    },

    updateName: debounce(function() {
      this.triggerUpdateName();
    }, 2000),
    updateContent: debounce(function() {
      this.triggerUpdateContent();
    }, 2000),

    goToItem(payload) {
      const { id } = payload;
      this.$router.push({ name: "ProjectDocument", params: { documentId: id } });
    },
  },
};
</script>
