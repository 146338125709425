<template>
  <div class="base-select-native w-100">
    <b-form-select
      :value="value"
      :name="name"
      @input="$emit('input', $event)"
      :placeholder="placeholder"
      :disabled="disabled"
      :id="id"
      :options="options"
    />
  </div>
</template>

<script>
import { BFormSelect } from "bootstrap-vue";

export default {
  name: "Native",
  components: { BFormSelect },
  props: {
    value: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      default: () => [],
    },
    name: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_settings";

.base-select-native {
  select {
    background-color: transparent !important;
    color: white;
    border: 0px !important;
    font-size: 1.3rem;
    background-image: url("data:image/svg+xml,%3Csvg width='22' height='12' viewBox='0 0 22 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.62916 1L11 9.98375L20.3492 1L21 1.38688L11 11L0.999999 1.38063L1.62916 1Z' fill='%23A9A7A1' stroke='%23A9A7A1'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: left;
    background-size: 10px 15px;
    width: fit-content !important;
    padding-left: 20px;
  }

  select[disabled="disabled"] {
    background-color: $white-100;
    color: $gray-darker;
  }

  select:focus {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
  }
}

small .base-select-native {
  select {
    font-size: 0.875em !important;
    font-weight: 400 !important;
    color: #d4d7d9 !important;
  }
}
</style>
